import { getAccessToken } from '@/utils/amplify/getAccessTokens';
import sendSentry from '@/utils/sentry';

const customFetch: (
  input: RequestInfo,
  init?: RequestInit,
) => Promise<Response> = async (input, init = {}) => {
  try {
    const accessToken = await getAccessToken();

    return fetch(input, {
      ...init,
      headers: {
        ...init.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    sendSentry(error);
    console.error('Error getting session', error);
    throw error;
  }
};

export default customFetch;
